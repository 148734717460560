import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "walletButton",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectMetamask()))
  }, [
    (_ctx.walletAddress)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.walletAddress), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, "Connect Wallet"))
  ]))
}