
import { BlockchainServices } from '@/services/blockchainServices';
import { WalletSignClient } from '@/services/Services';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class BetaWarningModal extends Vue {

    @Prop({
        default: ""
    }) walletAddress: string;

    @Prop({
        default: false
    }) alreadySign: boolean;

    showError: boolean = false;

    created() {
    }

    async acceptAndFirm() {
        var signature = await BlockchainServices.signMessage();
        WalletSignClient.verifySignature(signature, this.walletAddress)
        .then(signatureSuccess => {
            if(signatureSuccess) {
                store.state.alreadySigned = true;
                this.$opModal.closeAll();
            }
            else
                this.showError = true;
        })
    }

}
