
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { checkRoleIfIsAdmin } from '@/utils';
import ComingSoonModal from '../modals/comingSoonModal.vue';

@Options({})
export default class SiteLinks extends Vue {

    isAdmin: boolean = false;

    created() {
        this.isAdmin = checkRoleIfIsAdmin();
    }

    @Watch('$store.state.loggedUser')
    checkIfIsAdmin() {
        this.isAdmin = checkRoleIfIsAdmin();
    }

    comingSoonModal() {
        this.$opModal.show(ComingSoonModal, {});
        this.$emit('closeMenu');
    }

}
