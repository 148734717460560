
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class OperationErrorModal extends Vue {

    @Prop({
        default: ""
    }) message: string;

    created() {
    }

}
