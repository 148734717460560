
import { Options, Vue } from 'vue-class-component';
import { AirDropBugHuntingClient, WalletSignClient } from '@/services/Services';
import { connectMetamask } from '@/utils';
import { Watch } from 'vue-property-decorator';
import { checkRoleIfIsAdmin } from '@/utils';
import { LoginServices } from '@/services/LoginServices';
import SiteLinks from '../components/siteLinks.vue';
import BugReportCTA from '../components/bugReportCTA.vue';
import ConnectToMetamaskButton from '../components/connectToMetamaskButton.vue';
import OperationSucceedModal from '../modals/operationSucceedModal.vue';
import OperationErrorModal from '../modals/operationErrorModal.vue';
import ConnectWalletModal from '../modals/connectWalletModal.vue';
import BugReportModal from '../modals/bugReportModal.vue';
import BetaWarningModal from '../modals/betaWarningModal.vue';
import store from '@/store';
import * as OM from '@/Model';
import { BlockchainServices } from '@/services/blockchainServices';

@Options({
    components: {
        SiteLinks,
        BugReportCTA,
        ConnectToMetamaskButton
    }
})
export default class Layout extends Vue {

    isAdmin: boolean = false;
    showBetaWarningBanner: boolean = false;
    alreadySign: boolean = false;
    walletAddress: string = "";

    async created() {
        this.isAdmin = checkRoleIfIsAdmin();
        this.showBetaWarningBanner = store.state.walletAddress != "";
        this.alreadySign = store.state.alreadySigned;
    }

    @Watch('$store.state.loggedUser')
    checkIfIsAdmin() {
        this.isAdmin = checkRoleIfIsAdmin();
    }

    @Watch('$store.state.walletAddress')
    checkIfHasConnectedWalletAndHasToSign() {
        console.log("store.state.walletAddress");
        console.log(store.state.walletAddress);
        if(store.state.walletAddress == "")
            return;

        this.showBetaWarningBanner = true;
        WalletSignClient.checkIfAlreadySigned(store.state.walletAddress, false)
        .then(x => {
            this.alreadySign = x;
            
            if(!this.alreadySign)
                this.openBetaWarningModal();
        })
    }

    @Watch('$store.state.alreadySigned')
    updateSignStatus() {
        this.alreadySign = store.state.alreadySigned;
    }

    toggleMenu() {
        var burgerMenuButton = document.getElementById("burgerMenuButton");
        var menu = document.getElementById("menu");
        var navbar = document.getElementById("navbarMobile");

        if (burgerMenuButton.classList.contains("is-active")) {
            burgerMenuButton.classList.remove("is-active");
            menu.classList.remove("opened");
            navbar.classList.remove("menuOpened");
        }
        else {
            burgerMenuButton.classList.add("is-active");
            menu.classList.add("opened");
            navbar.classList.add("menuOpened");
        }
    }

    faucet() {
        if(!store.state.walletAddress)
            return;

        BlockchainServices.faucet()
        .then(x=> {
            if(x == "Ok")
            {
                this.$opModal.show(OperationSucceedModal, {})
            }
            else
            {
                this.$opModal.show(OperationErrorModal, {
                    message: x
                })
            }
        })

        // AirDropBugHuntingClient.faucet(store.state.walletAddress)
        // .then(x => {
        //     if(x == OM.FaucetResponse.Done) {
        //         this.$opModal.show(OperationSucceedModal, {})
        //     }
        //     else if (x == OM.FaucetResponse.AlreadyDone) {
        //         this.$opModal.show(OperationErrorModal, {
        //             message: "Faucet operation already done"
        //         })
        //     }
        //     else {
        //         this.$opModal.show(OperationErrorModal, {
        //             message: "Faucet availability sold out"
        //         })
        //     }
        // })
    }

    async bugReport() {
        if(store.state.walletAddress)
            this.openBugReportModal();
        else 
        {
            this.$opModal.show(ConnectWalletModal, {
                connectToMetamask: async () => {
                    var ris = await connectMetamask();
                    this.$opModal.closeLast();
                    this.openBugReportModal();
                },
                closeModal: () => {
                    this.$opModal.closeLast();
                }
            })
        }
    }
    openBugReportModal() {
        this.$opModal.show(BugReportModal, {});
    }

    openBetaWarningModal() {
        this.$opModal.show(BetaWarningModal, {
            walletAddress: store.state.walletAddress,
            alreadySign: this.alreadySign
        })
    }

    logout() {
        LoginServices.logout()
        .then(x => {
            // location.reload();
            this.$router.replace('/');
        });
    }

}
