
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class OperationSucceedModal extends Vue {

    created() {
    }

}
