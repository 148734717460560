
import { Options, Vue } from 'vue-class-component';
import { connectMetamask } from '@/utils';
import ConnectWalletModal from '../modals/connectWalletModal.vue';
import BugReportModal from '../modals/bugReportModal.vue';
import store from '@/store';

@Options({})
export default class BugReportCTA extends Vue {

    created() {}

    async bugReport() {
        if(store.state.walletAddress)
            this.openBugReportModal();
        else 
        {
            this.$opModal.show(ConnectWalletModal, {
                connectToMetamask: async () => {
                    var ris = await connectMetamask();
                    this.$opModal.closeLast();
                    this.openBugReportModal();
                },
                closeModal: () => {
                    this.$opModal.closeLast();
                }
            })
        }
    }
    openBugReportModal() {
        this.$opModal.show(BugReportModal, {});
    }

}
