
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class ConnectWalletModal extends Vue {

    @Prop() connectToMetamask: any;
    @Prop() closeModal: any;

    created() {
    }

}
