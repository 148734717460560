
import { BugManagementClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import OperationSucceedModal from './operationSucceedModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({})
export default class BugReportModal extends Vue {

    localFile: File = null;
    attachmentImageUrl: string = "";

    categories: OM.TextIdentifier[] = [];
    model: OM.BugReportVM = new OM.BugReportVM();

    showErrorText: boolean = false;

    created() {
        this.model.walletAddress = store.state.walletAddress;
        BugManagementClient.getAllCategories()
        .then(x => {
            this.categories = x;
        })
    }

    sendReport() {
        BugManagementClient.reportBug(this.model)
        .then(x => {
            if(x && this.localFile != null) {
                BugManagementClient.updateBugReportImage(x, this.localFile)
                .then(x => {
                    this.$opModal.closeLast();
                    this.$opModal.show(OperationSucceedModal, {});
                })
                .catch(y => {
                    console.log("Errore caricamento foto");
                    // console.log(y);
                    this.showErrorText = true;
                })
            } 
            else if(x && this.localFile == null) {
                this.$opModal.closeLast();
                this.$opModal.show(OperationSucceedModal, {});
            }
        })
        .catch(y => {
            console.log("Errore segnalazione bug");
            // console.log(y);
            this.showErrorText = true;
        })
    }

    get checkIfDisable() {
        return !this.model.category || !this.model.description;
    }

}
