
import { BlockchainServices } from '@/services/blockchainServices';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { connectMetamask } from '@/utils';
import store from '@/store';

@Options({})
export default class ConnectToMetamaskButton extends Vue {

    walletAddress: string = "";

    async created() {
        var checkIfConnected = await BlockchainServices.connectedMetamask();
        if(checkIfConnected) {
            var accounts = await window.ethereum.request({ method: 'eth_accounts' });
            this.walletAddress = accounts[0];
            store.state.walletAddress = this.walletAddress;
        }
    }

    async connectMetamask() {
        if(this.walletAddress) {
            this.walletAddress = this.walletAddress;
            store.state.walletAddress = this.walletAddress;
        }
        else 
            var ris = connectMetamask();
    }

    @Watch('$store.state.walletAddress', { deep: true })
    checkIfAccountConnected() {
        if(store.state.walletAddress) {
            this.walletAddress = store.state.walletAddress;
        }else{
            this.walletAddress = null;
        }
    }

}
